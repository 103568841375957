<template>
  <div class="member-search-wrapper">
    <!-- 搜索 -->
    <div class="part__search-container">
      <!-- <van-search @search="onSearch" v-model="keyword" placeholder="搜索"></van-search> -->
      <van-search v-model="keyword" placeholder="搜索"></van-search>
    </div>
    <!-- 选中bar -->
    <div class="selection-result-bar">
      <div class="bar__inner">
        <template v-if="selectedUids.length > 0">
          <div class="bar__item" v-for="uid in selectedUids" :key="uid" @click="onSelected" :data-uid="uid">
            <img :src="headpic(uid)" alt="" />
          </div>
        </template>
        <p class="hint" v-else>请选择好友</p>
      </div>
    </div>
    <!-- 列表 -->
    <div class="common-lists-container user-lists-container">
      <div class="list__inner" v-if="innerLists !== null && innerLists.length > 0">
        <div class="list__item" v-for="member in innerLists" :key="memberInfo(member, 'uid')" @click="onSelected"
          :data-uid="memberInfo(member, 'uid')" :class="{
            selected: isSelected(memberInfo(member, 'uid')),
            disabled: memberDisabled(member.ex),
          }">
          <div class="item__icon">
            <svg-icon icon-class="icon-choose"></svg-icon>
          </div>
          <div class="item__content-info item__user-info">
            <div class="user__headpic">
              <img :src="memberInfo(member, 'headimgurl')" alt="" />
            </div>
            <div class="user__name">
              {{ memberInfo(member, "nickname") | comment(memberInfo(member, "uid")) }}
            </div>
          </div>
          <div class="item__user-exinfo" v-if="member.ex">
            <template v-if="type === 'group-invite'">
              <div class="exinfo__status">{{ currentStatus(member.ex) }}</div>
              <div class="exinfo__comment" v-if="member.ex.status === 0">
                {{ expired(member.ex.expired_at) }}
              </div>
            </template>
            <template v-if="type === 'admin-set-up'">
              <div class="exinfo__status">管理员</div>
            </template>
          </div>
        </div>
      </div>
      <p v-else>{{ innerLists === null ? "加载中" : "暂无好友" }}</p>
    </div>
    <!-- 底部按钮 -->
    <div class="group__footer-fixed-nav">
      <div class="nav__item" @click="onCancel"><strong>取消</strong></div>
      <div class="nav__item black" @click="onSubmit"><strong>确认</strong></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'c_member-search',
  props: {
    type: {
      type: String,
      default: null,
    },
    lists: {
      type: Array,
      default: null,
    },
    maxLimit: {
      type: Number,
      default: 5,
    },
    selected: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      keyword: '',
      selectedUids: [],
      innerLists: null,
    };
  },
  watch: {
    lists: {
      handler(val) {
        this.innerLists = val;
      },
      immediate: true,
    },
    keyword() {
      this.onSearch();
    },
  },
  mounted() {
    if (this.selected) {
      this.selectedUids = this.selected.split(',');
      this.selectedUids = this.selectedUids.map(Number);
    }
  },
  methods: {
    onSelected(e) {
      const that = this;
      const uid = Number(e.currentTarget.dataset.uid);
      const uids = that.selectedUids;
      if (uids.indexOf(uid) >= 0) {
        // 存在
        uids.splice(uids.indexOf(uid), 1);
      } else if (that.maxLimit > 0 && uids.length >= that.maxLimit) {
        that.$notify({
          message: '超过人数限制',
        });
      } else {
        uids.push(uid);
      }
      that.selectedUids = uids;
    },
    headpic(uid) {
      const that = this;
      const user = that.lists.find((item) => Number(that.memberInfo(item, 'uid')) === Number(uid));
      return that.memberInfo(user, 'headimgurl');
    },
    onSearch() {
      // this.$emit('search', this.keyword);
      const that = this;
      if (that.keyword) {
        that.innerLists = that.lists.filter((item) => {
          const userNickname = item.info ? item.info.nickname : item.nickname;
          const nickname = userNickname.match(that.keyword);
          const comment = item.comment ? item.comment.match(that.keyword) : null;
          return !(nickname === null && comment === null);
        });
      } else {
        that.innerLists = that.lists;
      }
    },
    onSearchCancel() { },
    onSubmit() {
      const that = this;
      const notifyType = that.selectedUids.length === 0 ? 'error' : 'success';
      let message;
      if (that.selectedUids.length === 0) {
        message = '请选择至少一名好友';
      } else {
        message = that.type === 'group-invite' ? '邀请成功，等待好友加入 ' : '操作成功';
        that.$emit('submit', that.selectedUids);
      }
      const duration = that.type === 'group-invite' ? 3000 : 500;
      that.$notify({
        message,
        type: notifyType,
        duration,
      });
    },
    onCancel() {
      this.selectedUids = [];
      this.$emit('cancel');
    },
    memberDisabled(info) {
      if (!info) {
        return false;
      }
      const status = Number(info.status);
      const handlerStatus = Number(info.handler_status);

      if (this.type === 'group-invite') {
        return status === 0 || (status === 1 && (handlerStatus === 1 || handlerStatus === 99));
      }
      return info;
    },
    currentStatus(info) {
      const status = Number(info.status);
      const handlerStatus = Number(info.handler_status);
      /**
       * status
       * 0 未处理
       * 1 已经处理
       */

      /**
       * handleStatus
       * 0 未处理
       * 1 接受
       * 2 拒绝
       * 99 组长
       */
      if (status === 0) {
        return '已邀请';
      }
      if (status === 1) {
        if (handlerStatus === 1) {
          return '成员';
        }
        if (handlerStatus === 2) {
          return '拒绝，重新添加';
        }
        if (handlerStatus === 99) {
          return '组长';
        }
      }
      return '';
    },
    expired(expiredAt) {
      const rest = Number(expiredAt) - Date.now() / 1000;
      if (rest <= 0) {
        return '邀请已过时';
      }
      return `${Math.floor(rest / 3600)}H后失效`;
    },
    memberInfo(data, key) {
      try {
        return this.type === 'group-invite'
          || this.type === 'timeline-select'
          || this.type === 'topic-invite'
          ? data.info[key]
          : data[key];
      } catch (e) {
        console.log(data, key);
        return null;
      }
    },
    isSelected(uid) {
      return this.selectedUids.indexOf(Number(uid)) >= 0;
    },
  },
};
</script>

<style></style>
