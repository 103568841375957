<template>
  <div class="richtext__support-tag__container">
    <div ref="richtextContent" class="richtext__main-body" :id="contentId" :data-placeholder="placeholder"
      @focus="onEditorFocus" @blur="onEditorBlur" @input="handleInput" contenteditable="true">
    </div>
    <!-- 好友选择 -->
    <transition name="slide">
      <div class="common-search-list-container posts-friend-select" v-if="isShowFriendSelect">
        <member-search class="post-friend-select-wrapper" @search="onFriendSearch" @cancel="onFriendCancel"
          @submit="onFriendSubmit" type="timeline-select" :lists="friendLists" :max-limit="9"
          :selected="sendSelected"></member-search>
      </div>
    </transition>
  </div>
</template>

<script>
import memberSearch from './_member-search.vue';

const sign = '&';
const wrapperClassName = 'wrapperClickOnJump';

export default {
  name: 'common__rich-text-tag',
  components: {
    memberSearch,
  },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    relationship: {
      type: Array,
      default: null,
    },
    maxlength: {
      type: Number,
    },
  },
  computed: {
    count() {
      // 字符长度记数
      const num = this.maxlength - this.currentText.length;
      const text = num < 0 ? `已超出${Math.abs(num)}个字符` : `还可以输入${num}个字符`;
      return { num, text };
    },
    sendSelected() {
      if (this.latestedSelected.length > 0) {
        return this.latestedSelected.map((item) => item.uid).join(',');
      }
      return '';
    },
  },
  data() {
    return {
      contentId: `content${this.getGuid()}`,
      isLocked: false,
      isShowFriendSelect: false,
      friendLists: null,
      //   当前光标位置
      savedRange: null,
      lastSelected: [],
      latestedSelected: [],
      renderSelected: [],
      savedSelection: null,
      currentText: this.value,
      realText: '',
    };
  },
  watch: {
    relationship: {
      handler(val) {
        this.friendLists = val;
      },
      immediate: true,
    },
    value(val) {
      if (!this.isLocked) {
        this.$refs.richtextContent.innerHTML = val;
      }
    },
    currentText(val) {
      const num = this.maxlength - val.length;
      this.isLocked = num < 0;
    },
    renderSelected(val) {
      this.insertNode(val);
    },
  },
  mounted() {
    this.$refs.richtextContent.focus();
    // document.addEventListener('selectionchange', this.selectHandler);
  },
  destroyed() {
    // document.removeEventListener('selectionchange', this.selectHandler);
  },
  methods: {
    updateContent(val) {
      this.$emit('selected', this.latestedSelected);
      this.$emit('input', val);
    },
    rerender() {
      const str = this.$refs.richtextContent.innerHTML;
      const renderUID = this.regUID(str);
      if (renderUID && renderUID.length > 0) {
        const _tempFriendsPool = this.friendLists.filter((f) => renderUID.indexOf(f.info.uid.toString()) >= 0).map((m) => ({
          uid: m.info.uid,
          nickname: m.info.nickname,
          comment: m.comment,
        }));
        const reg = new RegExp('\\[u:\\d+\\]', 'mg');
        const _replacementStr = str.replaceAll(reg, (result) => {
          const r = new RegExp('\\d+', 'g');
          const uid = result.match(r)[0];
          const _f = _tempFriendsPool.find((tfpItem) => tfpItem.uid === Number(uid));
          return `&${_f.comment || _f.nickname}`;
        });
        this.$refs.richtextContent.innerHTML = _replacementStr;
      }
    },
    inputClick() {
      this.isLocked = true;
    },
    handleInput(e) {
      const { target, data } = e;
      if (data === sign) {
        this.callSelections();
      } else {
        this.updateContent(target.innerHTML);
      }
    },
    getGuid() {
      return `r${new Date().getTime()}d${Math.ceil(Math.random() * 1000)}`;
    },
    handleDelete() {
    },
    callSelections() {
      this.$refs.richtextContent.blur();
      this.onShowFriendChoose();
    },
    onFriendSearch(keyword) {
      const pool = [...this.relationship];
      if (keyword) {
        this.friendLists = pool.filter((item) => {
          const nickname = item.info.nickname.match(keyword);
          const comment = item.comment.match(keyword);
          return !(nickname === null && comment === null);
        });
      } else {
        this.friendLists = pool;
      }
    },
    onFriendCancel() {
      this.savedRange = null;
      this.onCloseFriendChoose();
    },
    onShowFriendChoose() {
      this.isShowFriendSelect = true;
      const htmlstr = this.$refs.richtextContent.innerHTML;
      if (htmlstr) {
        const currentExists = this.regUID(htmlstr);
        this.latestedSelected = currentExists ? this.latestedSelected.filter((item) => currentExists.indexOf(item.uid.toString()) >= 0) : [];
      } else {
        this.latestedSelected = [];
      }
      this.lastSelected = [...this.latestedSelected];
    },
    regUID(str) {
      const reg = new RegExp('(?<=data-uid=")\\d{1,5}', 'mg');
      const currentExists = str.match(reg);
      return currentExists;
    },
    onCloseFriendChoose() {
      this.isShowFriendSelect = false;
    },
    onFriendSubmit(uids) {
      this.latestedSelected = this.friendLists
        .filter((item) => uids.indexOf(Number(item.info.uid)) >= 0)
        .map((item) => {
          const temp = item;
          return {
            uid: temp.info.uid,
            renderName: `&${item.comment ? item.comment : item.info.nickname}`,
            gid: this.getGuid(),
            nickname: item.info.nickname,
          };
        });
      // 删除本次没有选中的
      const rest = this.lastSelected
        .filter((child) => {
          if (uids.indexOf(Number(child.uid)) === -1) {
            const t = document.getElementById(child.gid);
            if (t) {
              this.$refs.richtextContent.removeChild(t);
            }
            return false;
          }
          return true;
        })
        .map((item) => item.uid);
      this.renderSelected = this.latestedSelected.filter(
        (child) => rest.indexOf(Number(child.uid)) === -1,
      );
      this.lastSelected = [...this.latestedSelected];
      this.onCloseFriendChoose();
    },
    getEmptyNode() {
      return document.createTextNode('\u200B');
    },
    // 生成待插入nodes
    generateNode(nodes) {
      const emptyNode = this.getEmptyNode();
      if (nodes.length > 0) {
        const fragment = new DocumentFragment();
        nodes.forEach((item) => {
          const node = document.createElement('span');
          // node.textContent = `&[u:${item.uid}]`;
          node.textContent = item.renderName;
          node.id = item.gid;
          node.classList.add(wrapperClassName);
          node.dataset.uid = item.uid;
          node.dataset.nickname = item.nickname;
          node.contentEditable = false;
          node.appendChild(emptyNode);
          fragment.append(node);
        });
        return fragment;
      }

      return null;
    },
    insertNode(nodes) {
      const selection = window.getSelection();
      const _range = this.getRangeFromSavedSelection();
      const editor = this.$refs.richtextContent;

      const _fragment = this.generateNode(nodes);

      if (_fragment) {
        _range.deleteContents();
        _range.insertNode(_fragment);

        // 去除 &
        [...editor.childNodes].forEach((node) => {
          const temp = node;
          const { nodeName, nodeValue } = temp;
          if (nodeName === '#text' && nodeValue.indexOf(sign) >= 0) {
            temp.textContent = nodeValue.replaceAll(sign, '');
          }
        });

        // editor.appendChild(this.getEmptyNode());

        // 定位光标 默认是最后一个&用户
        const children = editor.querySelectorAll(`.${wrapperClassName}`);
        _range.setStartAfter(children[children.length - 1]);
        selection.removeAllRanges();
        selection.addRange(_range);

        // 更新内容
        this.updateContent(this.$refs.richtextContent.innerHTML);
        editor.focus();
      }
    },
    selectHandler(e) {
      e.preventDefault();
      // 监听选定文本的变动
    },
    // 保存光标位置
    setLastRange() {
      const sel = window.getSelection() || document.getSelection();
      const range = sel.rangeCount > 0 ? sel.getRangeAt(0) : null;
      if (
        range
        && (range.commonAncestorContainer.ownerDocument.activeElement.id === this.contentId
          || range.commonAncestorContainer.parentNode.id === this.contentId)
      ) {
        this.savedRange = range;
        this.savedSelection = {
          anchorNode: range.startContainer,
          anchorOffset: range.startOffset,
        };
      }
    },
    // 从保存的光标位置获取 range
    getRangeFromSavedSelection() {
      let range = null;
      if (this.savedSelection && this.savedSelection.anchorNode) {
        range = document.createRange();
        range.setStart(
          this.savedSelection.anchorNode,
          this.savedSelection.anchorOffset,
        );
        range.collapse(true);
      }
      return range;
    },
    onEditorBlur() {
      this.isLocked = false;
      this.setLastRange();
    },
    onEditorFocus() {
      this.isLocked = true;
    },
  },
};
</script>

<style scoped>
.richtext__main-body {
  display: flex;
  flex-wrap: wrap;
  line-height: 1;
  align-items: flex-start;
}
</style>
